import React from "react";
import styled from "styled-components";

import Layout from "../components/Layout";
import Footer from "../components/Footer";

import { device } from "../globalStyles";

import { useLangContext } from "../context/langContext";

import TermsConditionsDutch from "../constants/terms-conditions/terms-conditions-dutch";
import TermsConditionsEnglish from "../constants/terms-conditions/terms-conditions-english";
import TermsConditionsFrench from "../constants/terms-conditions/terms-conditions-french";
import Seo from "../components/SEO";

const Container = styled.div`
  padding: var(--gutter);
  margin: auto;
  max-width: 872px;
  padding-top: 24px;
  @media (${device.minTablet}) {
    padding-top: 80px;
  }

  & > p {
    margin-bottom: 16px;
    & + h5 {
      margin-top: 32px;
    }
  }

  & > ul {
    list-style: disc;
    li {
      margin-inline-start: 2em;
      padding-inline-start: 1em;
      margin-block-end: 0.8em;
      &:last-of-type {
        margin-bottom: 32px;
      }
    }
  }

  b {
    font-family: "Roboto", sans-serif;
  }
`;

export const TcTitle = styled.h1`
  margin-bottom: 32px;
`;

export const TcSubtitle = styled.h5`
  margin-bottom: 16px;
`;

const TermsConditions = () => {
  const { lang } = useLangContext();

  return (
    <Layout title="Terms and conditions">
      <Container>
        {lang === "nl" && <TermsConditionsDutch />}
        {lang === "en" && <TermsConditionsEnglish />}
        {lang === "fr" && <TermsConditionsFrench />}
      </Container>
      <Footer />
    </Layout>
  );
};

export default TermsConditions;

export const Head = () => <Seo />;
